import PropTypes from 'prop-types'
import React, { Component, createContext } from 'react'
import { Helmet } from 'react-helmet'
import { Alert, ToastProvider } from 'vtex.styleguide'

import APM from './commons/apm'
import Box from './components/shared/Box'
import getOAuthErrorMessage from './components/utils/getOAuthErrorMessage'

export const PageWrapperContext = createContext({})

APM.init()

class PageWrapper extends Component {
  constructor(props) {
    super(props)

    const error = this.props.query.error

    this.state = {
      errorMessage: error && getOAuthErrorMessage(error),
      isUnauthorized: false,
    }
  }

  handleErrorMessage = errorMessage => {
    this.setState({ errorMessage })
  }

  handleClearErrorMessage = () => {
    this.setState({ errorMessage: '' })
  }

  handleIsUnauthorized = value => {
    if (typeof value !== 'boolean') {
      return
    }

    this.setState({ isUnauthorized: value })
  }

  setupApmTags = () => {
    const host = location.host
    const subDomain = host.split('.')[0]
    const hasWorkspace = subDomain.includes('--')
    const workspaceIsMaster = hasWorkspace
      ? subDomain.split('--')[0] === 'master'
      : false

    const tags = {
      account: hasWorkspace ? subDomain.split('--')[1] : subDomain,
      workspace:
        (hasWorkspace && workspaceIsMaster) || !hasWorkspace
          ? null
          : subDomain.split('--')[0],
    }

    APM.setupTags(tags)
  }

  getReturnUrl = () => {
    const {
      query: { returnUrl },
    } = this.props
    if (!returnUrl) {
      return '/'
    }
    const redirectHref =
      returnUrl && new URL(returnUrl.split(/[?]/)[0], location.href).href
    const adminLoginNewHref = new URL('admin/login', location.origin).href
    const adminLoginOldHref = new URL(
      '_v/segment/admin-login/v1/login',
      location.origin
    ).href
    if (
      redirectHref === adminLoginNewHref ||
      redirectHref === adminLoginOldHref
    ) {
      return '/'
    }
    return `${returnUrl}${location ? decodeURIComponent(location.hash) : ''}`
  }

  componentDidMount() {
    this.setupApmTags()
  }

  render() {
    const {
      query: { email: emailQuery },
      children,
    } = this.props

    return (
      <React.Fragment>
        <Helmet>
          <script
            language="javascript"
            src="//io.vtex.com.br/rc/rc.js"
            type="text/javascript"
          />
          <style type="text/css">
            @import url(//io.vtex.com.br/fonts/fabriga/stylesheet.css);
          </style>
        </Helmet>
        <div className="w-100 vh-100 ph7-ns mw6 center justify-center flex-ns flex-column-ns">
          <Box>
            <ToastProvider positioning="window">
              {this.state.errorMessage && (
                <div className="nt4 mb6">
                  <Alert
                    type="error"
                    onClose={() => this.handleErrorMessage('')}>
                    <span className="t-small t-body-ns">
                      {this.state.errorMessage}
                    </span>
                  </Alert>
                </div>
              )}
              <PageWrapperContext.Provider
                value={{
                  emailQuery,
                  returnUrlQuery: this.getReturnUrl(),
                  handleErrorMessage: this.handleErrorMessage,
                  handleClearErrorMessage: this.handleClearErrorMessage,
                  handleIsUnauthorized: this.handleIsUnauthorized,
                  isUnauthorized: this.state.isUnauthorized,
                }}>
                {children}
              </PageWrapperContext.Provider>
            </ToastProvider>
          </Box>
        </div>
      </React.Fragment>
    )
  }
}

PageWrapper.propTypes = {
  query: PropTypes.object,
  children: PropTypes.node,
}

export default PageWrapper
