import * as Sentry from '@sentry/react'

const init = () => {
  Sentry.init({
    environment: process.env.NODE_ENV,
    enabled: false,
    dsn:
      'https://d96f0252a0b0d7a6c004a9631e000955@o191317.ingest.us.sentry.io/4508721329602560',
    tracesSampleRate: 1,
  })
}

const setupTags = (tags: Record<string, string | number | boolean>) => {
  Object.entries(tags).forEach(entry => {
    const [key, value] = entry

    Sentry.setTag(key, value)
  })
}

const { captureException } = Sentry

const APM = {
  captureException,
  init,
  setupTags,
}

export default APM
